import http from "./httpService";

const apiUrl = process.env.REACT_APP_API_URL;

interface ImageDb {
  loading: boolean;
  data: {
    [x: string]: {
      image: string;
      sku: string;
    };
  };
}

export async function getImageDb() {
  //get cached imageDb
  const cachedImageDbJSON = sessionStorage.getItem("imageDb");
  if (cachedImageDbJSON) return JSON.parse(cachedImageDbJSON);

  //else get it from the service provider
  const { data } = await http.get(`${apiUrl}products/images`);
  const imageDb = {
    loading: false,
    data,
  };
  sessionStorage.setItem("imageDb", JSON.stringify(imageDb));
  return imageDb;
}

export function getImageObject(sku: string, imageDb: ImageDb) {
  if (!sku) return;
  //Image for A or B Stock is the same
  const modifiedSku = sku.substr(sku.length - 2, 2) === "-U" ? sku.substr(0, sku.length - 2) : sku;
  if (imageDb.loading) return;
  try {
    return imageDb.data[modifiedSku];
  } catch (err) {
    return false;
  }
}

export function getImageUrl(sku: string, imageDb: ImageDb) {
  if (!sku) return;
  //Image for A or B Stock is the same
  const modifiedSku = sku.substr(sku.length - 2, 2) === "-U" ? sku.substr(0, sku.length - 2) : sku;

  if (imageDb.loading) return;
  try {
    return imageDb.data[modifiedSku].image;
  } catch (err) {
    return false;
  }
}

export function getThumbUrl(sku: string, imageDb: ImageDb) {
  if (!sku) return;
  //Image for A or B Stock is the same
  const modifiedSku = sku.substr(sku.length - 2, 2) === "-U" ? sku.substr(0, sku.length - 2) : sku;

  if (imageDb.loading) return;
  if (imageDb)
    try {
      return imageDb.data[modifiedSku].image;
    } catch (err) {
      return false;
    }
}

export async function loadImageObject(sku: string) {
  //Image for A or B Stock is the same
  const modifiedSku = sku.substr(sku.length - 2, 2) === "-U" ? sku.substr(0, sku.length - 2) : sku;
  const imageDb = await getImageDb();
  if (!imageDb) return;
  return imageDb.data[modifiedSku];
}

const imageService = {
  getImageDb,
  getImageObject,
  getImageUrl,
  getThumbUrl,
  loadImageObject,
};

export default imageService;
